import * as React from "react";
import styled from "styled-components";

const StyledNavigation = styled.section`
  grid-row: 4 / 5;
  height: 2rem;
`;

interface NavigationProps {}

const Navigation: React.FunctionComponent<NavigationProps> = (props) => {
  return <StyledNavigation></StyledNavigation>;
};

export default Navigation;

import { createGlobalStyle } from "styled-components";
import theme from "../themes/theme";

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box ;
    }
    body {
        margin: 0;
        font-family: ${theme.fonts.globalFontFamily};
        background-color: ${theme.colors.globalBackground} ;
        color: ${theme.colors.globalText}
    }
`;

export default GlobalStyle;

import * as React from "react";
import styled from "styled-components";

const StyledBranding = styled.section`
  grid-row: 2 / 3;
  height: 3rem;
  background-color: black;
`;

interface BrandingProps {}

const Branding: React.FunctionComponent<BrandingProps> = (props) => {
  return <StyledBranding>Typing</StyledBranding>;
};

export default Branding;

import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootStateType } from "../../store/store";

const StyledMetricsBox = styled.section`
  grid-row: 6 / 7;
  height: 7rem;
  /* background-color: black; */
  border: 1px solid gray;
  font-size: 1rem;
`;

interface MetricsBoxState {}

interface MetricsBoxProps {}

const MetricsBox: React.FunctionComponent<MetricsBoxProps> = (props) => {
  const {
    keystrokes: [
      totalKeystrokes,
      correctKeystrokes,
      incorrectKeystrokes,
      jibberishKeystrokes,
    ],
    words: [totalWords, totalAttempted, correctWords, inCorrectWords],
    grossSpeed,
    netSpeed,
    accuracy,
  } = useSelector((state: RootStateType) => state.ContentBox.metrics);
  return (
    <StyledMetricsBox>
      Speed: ( {grossSpeed} | {netSpeed} ) &nbsp; &nbsp; &nbsp; Accuracy:{" "}
      {accuracy}
      <br />
      Keystrokes: ( {totalKeystrokes} | {correctKeystrokes} |{" "}
      {incorrectKeystrokes} | {jibberishKeystrokes} )
      <br />
      Words: ( {totalWords} | {totalAttempted} | {correctWords} |{" "}
      {inCorrectWords} )
    </StyledMetricsBox>
  );
};

export type { MetricsBoxState };
export default MetricsBox;

import * as React from "react";
import styled from "styled-components";
import {
  CONTENT_BOX_MAX_CHARS_PER_LINE,
  CONTENT_BOX_MAX_LINES,
} from "../contants";
import Content from "./components/Content";

const StyledContentBox = styled.section`
  width: ${CONTENT_BOX_MAX_CHARS_PER_LINE}ch;
  height: ${CONTENT_BOX_MAX_LINES}lh;
  font-size: ${(p) => p.theme.fonts.contentBox.content};
  grid-row: 8 / 9;
  box-sizing: content-box;
  border: 1px solid gray;
`;

interface LetterState {
  char: string;
  timestamp: number;
  isCorrect: boolean;
  view: boolean;
}

interface MetricsState {
  keystrokes: [number, number, number, number];
  words: [number, number, number, number];
  accuracy: number | string;
  grossSpeed: number | string;
  netSpeed: number | string;
  letterWise: Record<string, any>;
}

interface ContentBoxSettingsState {
  caret: {
    showCaret: boolean;
    blinkCaret: boolean;
    caretStyle: "block" | "box" | "line" | "underline";
    caretMovement: "none" | "slow" | "medium" | "fast";
  };
  idealCaret: {
    showCaret: boolean;
    blinkCaret: boolean;
    caretStyle: "block" | "box" | "line" | "underline";
    caretMovement: "none" | "slow" | "medium" | "fast";
    position: number;
    speed: number;
  };
  whiteSpace: "none" | "bar" | "bullet";
  sound: {
    playSoundWhen: "none" | "everytime" | "inCorrect" | "correct";
    volume: number;
    correctLetterSound: string;
    incorrectLetterSound: string;
  };
  spaceSkipWords: boolean;
  stopCaretOnError: "letter" | "word" | "none";
  backspaceBlocked: "none" | "word" | "yes";
}

interface ContentBoxState {
  actualContent: string;
  enteredContent: Array<LetterState>;
  metrics: MetricsState;
  settings: ContentBoxSettingsState;
}

interface ContentBoxProps {}

const ContentBox: React.FunctionComponent<ContentBoxProps> = () => {
  return (
    <StyledContentBox>
      <Content />
    </StyledContentBox>
  );
};

export default ContentBox;
export type { ContentBoxState, LetterState, MetricsState };
export type { ContentBoxProps };

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import { RootStateType } from "../../../store/store";
import theme from "../../../themes/theme";
import { CONTENT_BOX_MAX_CHARS_PER_LINE } from "../../contants";
import { incrementIdealCaretPosition } from "../ContentBox.slice";

const StyledIdealCaret = styled.span<{
  caretStyle: string;
  caretMovement: string;
  position: number;
}>`
  background-color: transparent;
  position: absolute;
  min-width: ${(p) => (p.caretStyle === "line" ? "2px" : "0")};
  max-width: 100%;
  width: ${(p) => p.position % (CONTENT_BOX_MAX_CHARS_PER_LINE - 1)}ch;
  top: ${(p) =>
    Math.floor(p.position / (CONTENT_BOX_MAX_CHARS_PER_LINE - 1))}lh;
  height: 1lh;
  left: 0;
  ${(p) => {
    switch (p.caretMovement) {
      case "none":
        return css`
          transition: all 0s linear;
        `;
      case "slow":
        return css`
          transition: all 200ms linear;
        `;
      case "medium":
        return css`
          transition: all 150ms linear;
        `;
      case "fast":
        return css`
          transition: all 50ms linear;
        `;
    }
  }}
`;

const blinkingAnimation = (caretStyle: string, blinkCaret: boolean) => {
  switch (caretStyle) {
    case "line":
      return keyframes`
        from,to {
          
          border-radius: 2px;
          top: 0;
          right: 0;
          bottom:0;
          border: 1px solid ${
            blinkCaret ? "transparent" : `${theme.colors.contentBox.idealCaret}`
          };
        }
        50% {
          border-radius: 2px;
          top: 0;
          right: 0;
          bottom:0;
          border: 1px solid ${theme.colors.contentBox.idealCaret};
        }
      `;
    case "underline":
      return keyframes`
      from,to {
        right: -1ch;
        bottom:0;
        width: 1ch;
        border: 1px solid ${
          blinkCaret ? "transparent" : `${theme.colors.contentBox.idealCaret}`
        };
      }
      50% {
        right: -1ch;
        bottom:0;
        width: 1ch;
        border: 1px solid ${theme.colors.contentBox.idealCaret};
      }
    `;
    case "box":
      return keyframes`
        from,to {
          right: -1ch;
          top: 0;
          bottom: 0;
          width: 1ch;
          border: 1px solid ${
            blinkCaret ? "transparent" : `${theme.colors.contentBox.idealCaret}`
          };
        }
        50% {
         right: -1ch;
         top: 0;
          width: 1ch;
          border: 1px solid ${theme.colors.contentBox.idealCaret};
        }
      `;
    case "block":
      return keyframes`
        from,to {
          right: -1ch;
          top: 0;
          bottom: 0;
          width: 1ch;
          background-color: ${
            blinkCaret ? "transparent" : `${theme.colors.contentBox.idealCaret}`
          };
        }
        50% {
         right: -1ch;
         top: 0;
          width: 1ch;
          background-color: ${theme.colors.contentBox.idealCaret};
        }
      `;
  }
};

const StyledIdealCaretSymbol = styled.span<{
  caretStyle: string;
  blinkCaret: boolean;
  showCaret: boolean;
}>`
  position: absolute;
  animation: ${(p) => blinkingAnimation(p.caretStyle, p.blinkCaret)} 0.8s linear
    infinite;
`;

interface IdealCaretProps {}

const IdealCaret: React.FunctionComponent<IdealCaretProps> = () => {
  const { speed, position, ...idealCaret } = useSelector(
    (state: RootStateType) => state.ContentBox.settings.idealCaret,
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    const id = setTimeout(() => {
      dispatch(incrementIdealCaretPosition());
    }, speed);
    return () => {
      clearTimeout(id);
    };
  }, [position, speed, dispatch]);

  return (
    <StyledIdealCaret {...idealCaret} position={position}>
      <StyledIdealCaretSymbol {...idealCaret} />
    </StyledIdealCaret>
  );
};

export default IdealCaret;

import * as React from "react";
import styled from "styled-components";

const StyledKeyboard = styled.section`
  grid-row: 10 / 11;
  /* background-color: black; */
`;

interface KeyboardProps {}

const Keyboard: React.FunctionComponent<KeyboardProps> = (props) => {
  return <StyledKeyboard></StyledKeyboard>;
};

export default Keyboard;

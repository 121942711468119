import ThemeType from "./theme.type";

const theme: Array<ThemeType> = [
  {
    name: "Default",
    colors: {
      globalBackground: "black",
      globalText: "white",
      contentBox: {
        untypedLetter: "gray",
        correctLetter: "white",
        incorrectLetter: "red",
        contentSeparator: "gray",
        caret: "white",
        idealCaret: "red",
        background: "black",
      },
    },
    fonts: {
      globalFontFamily: "'Roboto Mono', monospace",
      contentBox: {
        content: "1.75rem",
      },
    },
  },
  {
    name: "ReactColored",
    colors: {
      globalBackground: "#282c34",
      globalText: "white",
      contentBox: {
        untypedLetter: "#61dafbff",
        correctLetter: "#61dafb60",
        incorrectLetter: "#fcaca3",
        contentSeparator: "#61dafb90",
        caret: "white",
        idealCaret: "white",
        background: "#282c34",
      },
    },
    fonts: {
      globalFontFamily: "'Roboto Mono', monospace",
      contentBox: {
        content: "1.75rem",
      },
    },
  },
];

const currentTheme = "ReactColored";
export default theme.filter((theme) => theme.name === currentTheme)[0];

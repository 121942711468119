import { createSlice } from "@reduxjs/toolkit";
import { KEYBOARD_KEYS } from "../contants";
import { ContentBoxState } from "./ContentBox";

const ContentBoxSlice = createSlice({
  name: "ContentBox",
  initialState: {
    actualContent:
      "actions speak louder than words make sure you practice the stuff well i wish to learn touch typing i love to program practice makes man perfect and obviously why not rich",
    enteredContent: [],
    metrics: {
      keystrokes: [0, 0, 0, 0],
      words: [0, 0, 0, 0],
      accuracy: 0,
      grossSpeed: 0,
      netSpeed: 0,
      letterWise: {},
    },
    settings: {
      caret: {
        showCaret: true,
        blinkCaret: true,
        caretStyle: "line",
        caretMovement: "medium",
      },
      idealCaret: {
        showCaret: false,
        blinkCaret: false,
        caretStyle: "line",
        caretMovement: "slow",
        position: 0,
        speed: 400,
      },
      whiteSpace: "bullet",
      sound: {
        playSoundWhen: "everytime",
        volume: 100,
        correctLetterSound: "./sounds/sound-1.mp3",
        incorrectLetterSound: "./sounds/sound-4.mp3",
      },
      spaceSkipWords: false,
      stopCaretOnError: "none",
      backspaceBlocked: "yes",
    },
  } as ContentBoxState,
  reducers: {
    addCharToUserInput(state, { payload: { char, timestamp } }) {
      const viewedContentSize = state.enteredContent.filter(
        (item) => item.view,
      ).length;
      const nextExpectedChar = state.actualContent[viewedContentSize];
      const lastIndexOfViewedLetter = state.enteredContent
        .map((item, idx) => (item.view ? idx : -1))
        .filter((item) => item !== -1)
        .pop();

      // Lesson finished
      if (viewedContentSize === state.actualContent.length) {
        console.log("Finished!");
        return;
      }

      // Ignores certain characters
      if (Object.values(KEYBOARD_KEYS.IGNORED).includes(char)) {
        return;
      }

      // Handles backspace
      if (KEYBOARD_KEYS.CONSIDERED.BACKSPACE === char) {
        switch (state.settings.backspaceBlocked) {
          case "none":
            lastIndexOfViewedLetter !== undefined &&
              state.enteredContent.splice(lastIndexOfViewedLetter, 1);
            return;
          case "yes":
            return;
          case "word":
            const previousSpaceIndex = state.actualContent
              .substring(0, viewedContentSize)
              .lastIndexOf(" ");
            if (viewedContentSize - 1 > previousSpaceIndex)
              lastIndexOfViewedLetter !== undefined &&
                state.enteredContent.splice(lastIndexOfViewedLetter, 1);
            return;
        }
      }

      // Decide if the letter is correct or not?
      const isCorrect = char === nextExpectedChar;

      // Play appropriate sound
      if (state.settings.sound.playSoundWhen !== "none") {
        const correctSound = new Audio(
          require(`${state.settings.sound.correctLetterSound}`),
        );
        const inCorrectSound = new Audio(
          require(`${state.settings.sound.incorrectLetterSound}`),
        );
        correctSound.volume = state.settings.sound.volume / 100;
        isCorrect &&
          state.settings.sound.playSoundWhen === "correct" &&
          correctSound.play();
        !isCorrect &&
          state.settings.sound.playSoundWhen === "inCorrect" &&
          inCorrectSound.play();
        state.settings.sound.playSoundWhen === "everytime" &&
          ((isCorrect && correctSound.play()) ||
            (!isCorrect && inCorrectSound.play()));
      }

      // Based on the user selection, allow the user to skip to another next word if space is pressed when not expected
      if (
        state.settings.spaceSkipWords &&
        char === " " &&
        nextExpectedChar !== " "
      ) {
        const noOfCharsToTravelUntilNextSpace = state.actualContent
          .substring(viewedContentSize)
          .indexOf(" ");
        Array.from(Array(noOfCharsToTravelUntilNextSpace).keys()).forEach(
          (idx) => {
            state.enteredContent.push({
              char: state.actualContent[viewedContentSize + idx],
              timestamp: -1,
              isCorrect: false,
              view: true,
            });
          },
        );
        return;
      }

      // Based on the user selection, when to allow the user to make mistakes
      switch (state.settings.stopCaretOnError) {
        case "letter":
          state.enteredContent.push({
            char: nextExpectedChar,
            timestamp,
            isCorrect: isCorrect,
            view: isCorrect,
          });
          break;
        case "none":
          if (isCorrect) {
            state.enteredContent.push({
              char,
              timestamp,
              isCorrect: isCorrect,
              view: true,
            });
          } else {
            state.enteredContent.push({
              char: state.actualContent[state.enteredContent.length],
              timestamp,
              isCorrect: false,
              view: true,
            });
          }
          break;
        case "word":
          if (isCorrect) {
            state.enteredContent.push({
              char,
              timestamp,
              isCorrect: true,
              view: true,
            });
          } else {
            if (nextExpectedChar !== " ") {
              state.enteredContent.push({
                char: nextExpectedChar,
                timestamp,
                isCorrect: false,
                view: true,
              });
            } else {
              state.enteredContent.push({
                char: nextExpectedChar,
                timestamp,
                isCorrect: false,
                view: false,
              });
            }
          }
          break;
      }

      //----Metrics---

      //keystrokes
      const totalKeystrokes = state.enteredContent.length;
      const correctKeystrokes = state.enteredContent.filter(
        (item) => item.isCorrect && item.view,
      ).length;
      const incorrectKeystrokes = state.enteredContent.filter(
        (item) => !item.isCorrect && item.view,
      ).length;
      state.metrics.keystrokes = [
        totalKeystrokes,
        correctKeystrokes,
        incorrectKeystrokes,
        state.enteredContent.filter((item) => !item.view).length,
      ];

      // words
      const totalAttemptedWords = state.actualContent
        .substring(0, viewedContentSize)
        .split(" ").length;
      //logic to find correct words- first convert all letters into booleans resembling there correctness,and spaces to " ", and then put them in an array
      let wordIdx = 0;
      let wordArr: Array<Array<boolean>> = [];
      state.enteredContent
        .filter((item) => item.view)
        .forEach((item) => {
          if (item.char !== " ") {
            wordArr[wordIdx] =
              wordArr[wordIdx] !== undefined
                ? [...wordArr[wordIdx], item.isCorrect]
                : [item.isCorrect];
          } else {
            wordIdx++;
          }
        });
      let words = wordArr.map((word) => word.every((item) => item));
      state.metrics.words = [
        state.actualContent.split(" ").length,
        totalAttemptedWords,
        words.filter((item) => item).length,
        words.filter((item) => !item).length,
      ];

      // accuracy
      state.metrics.accuracy = (
        (correctKeystrokes / totalKeystrokes) *
        100
      ).toFixed(2);

      // speed
      // TODO: WITH OR WITHOUT JIBBERISH
      const minutes =
        (state.enteredContent.filter((item) => item.view)[viewedContentSize]
          .timestamp -
          state.enteredContent.filter((item) => item.view)[0].timestamp) /
        1000 /
        60;
      const grossWpm = viewedContentSize / 5 / minutes;
      const netWpm = grossWpm - incorrectKeystrokes / minutes;
      state.metrics.grossSpeed = grossWpm.toFixed(2);
      state.metrics.netSpeed = netWpm && netWpm < 0 ? 0 : netWpm.toFixed(2);
    },
    incrementIdealCaretPosition(state) {
      if (
        state.enteredContent.length !== state.actualContent.length &&
        state.actualContent.length !== state.settings.idealCaret.position
      ) {
        state.settings.idealCaret.position =
          state.settings.idealCaret.position + 1;
      }
    },
  },
});

const { actions, reducer } = ContentBoxSlice;
const { addCharToUserInput, incrementIdealCaretPosition } = actions;

export { addCharToUserInput, incrementIdealCaretPosition };
export default reducer;

import * as React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootStateType } from "../../../store/store";

const StyledBullet = styled.span<{ isCorrect?: boolean }>`
  display: inline-block;
  width: 1ch;
  height: 1ch;
  background-color: transparent;
  position: relative;
  z-index: -10;

  &::before {
    content: "";
    position: absolute;
    width: 0.35ch;
    height: 0.35ch;
    left: calc(50% - 0.175ch);
    top: calc(60% - 0.175ch);
    background-color: ${(p) =>
      p.isCorrect === undefined
        ? p.theme.colors.contentBox.contentSeparator
        : p.isCorrect
          ? p.theme.colors.contentBox.correctLetter
          : p.theme.colors.contentBox.incorrectLetter};
    border-radius: 50%;
  }
`;

const StyledBar = styled.span<{ isCorrect?: boolean }>`
  display: inline-block;
  width: 1ch;
  height: 1ch;
  background-color: transparent;
  position: relative;
  z-index: -10;

  &::before {
    content: "";
    position: absolute;
    width: 0.4ch;
    left: calc(50% - 0.2ch - 2px);
    bottom: 0;
    border-style: solid;
    border-color: white;
    border-width: 2px;
    border-color: ${(p) =>
      p.isCorrect === undefined
        ? p.theme.colors.contentBox.contentSeparator
        : p.isCorrect
          ? p.theme.colors.contentBox.correctLetter
          : p.theme.colors.contentBox.incorrectLetter};
    border-top-color: transparent;
  }
`;

const StyledNone = styled.span`
  display: inline-block;
  width: 1ch;
  height: 1ch;
  background-color: ${(p) => p.theme.colors.contentBox.background};
`;

interface ContentSeparatorProps {
  isCorrect?: boolean;
}

const ContentSeparator: React.FunctionComponent<ContentSeparatorProps> = ({
  isCorrect,
}) => {
  const whiteSpace = useSelector(
    (state: RootStateType) => state.ContentBox.settings.whiteSpace,
  );

  return whiteSpace === "bullet" ? (
    <StyledBullet isCorrect={isCorrect} />
  ) : whiteSpace === "bar" ? (
    <StyledBar isCorrect={isCorrect} />
  ) : (
    <StyledNone />
  );
};

export default ContentSeparator;

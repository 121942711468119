const KEYBOARD_KEYS = {
  IGNORED: {
    SHIFT: "Shift",
    TAB: "Tab",
    CMD: "Meta",
    CAPSLOCK: "CapsLock",
    ALT: "Alt",
    ENTER: "Enter",
    ARROWUP: "ArrowUp",
    ARROWDOWN: "ArrowDown",
    ARROWRIGHT: "ArrowRight",
    ARROWLEFT: "ArrowLeft",
    ESCAPE: "Escape",
  },
  CONSIDERED: {
    BACKSPACE: "Backspace",
  },
};

const CONTENT_BOX_MAX_CHARS_PER_LINE = 70;
const CONTENT_BOX_MAX_LINES = 3;

export { KEYBOARD_KEYS, CONTENT_BOX_MAX_CHARS_PER_LINE, CONTENT_BOX_MAX_LINES };

import * as React from "react";
import styled from "styled-components";
import ContentSeparator from "./ContentSeparator";

const StyledContentLetter = styled.span<{ isCorrect?: boolean }>`
  color: ${(p) =>
    p.isCorrect === undefined
      ? p.theme.colors.contentBox.untypedLetter
      : p.isCorrect
        ? p.theme.colors.contentBox.correctLetter
        : p.theme.colors.contentBox.incorrectLetter};
  display: inline-block;
  min-width: 1ch;
`;

interface ContentLetterProps {
  char: string;
  isCorrect: boolean;
}

const ContentLetter: React.FunctionComponent<ContentLetterProps> = ({
  char,
  isCorrect,
}) => {
  return char === " " ? (
    <ContentSeparator isCorrect={isCorrect} />
  ) : (
    <StyledContentLetter isCorrect={isCorrect}>{char}</StyledContentLetter>
  );
};

export default ContentLetter;

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootStateType } from "../../../store/store";
import { LetterState } from "../ContentBox";
import { addCharToUserInput } from "../ContentBox.slice";
import Caret from "./Caret";
import ContentLetter from "./ContentLetter";
import IdealCaret from "./IdealCaret";

const StyledContent = styled.section`
  background-color: ${(p) => p.theme.colors.contentBox.background};
  color: ${(p) => p.theme.colors.contentBox.untypedLetter};
  position: relative;
  z-index: 0;
  outline: none;
`;

interface ContentProps {}

const Content: React.FunctionComponent<ContentProps> = (props) => {
  const dispatch = useDispatch();

  const {
    actualContent,
    enteredContent,
    settings: {
      caret: { showCaret },
      idealCaret: { showCaret: showIdealCaret },
    },
  } = useSelector((state: RootStateType) => state.ContentBox);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    dispatch(
      addCharToUserInput({ char: e.key, timestamp: Date.now() } as LetterState),
    );
  };

  const contentToBeRendered = actualContent
    .split("")
    .map((char, idx) => (
      <ContentLetter
        char={char}
        key={idx}
        isCorrect={enteredContent.filter((item) => item.view)[idx]?.isCorrect}
      />
    ));

  return (
    <StyledContent tabIndex={0} onKeyDown={handleKeyPress}>
      {showIdealCaret && <IdealCaret />}
      {showCaret && (
        <Caret position={enteredContent.filter((item) => item.view).length} />
      )}
      {contentToBeRendered}
    </StyledContent>
  );
};

export default Content;

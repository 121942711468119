import * as React from "react";
import styled from "styled-components";

const StyledAppLayout = styled.section`
  width: 100vw;
  height: 100vh;
  display: grid;
  font-size: ${(p) => p.theme.fonts.contentBox.content};
  grid-template-columns: auto min-content auto;
`;

const StyledMainContent = styled.section`
  border: 1px solid gray;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  grid-template-rows: 2rem max-content 2rem max-content 2rem max-content 2rem max-content 2rem auto;
  & > div {
    border: 1px solid gray;
  }
`;

interface AppLayoutProps {
  adSection: React.ComponentType;
  branding: React.ComponentType;
  navigation: React.ComponentType;
  metrics: React.ComponentType;
  contentBox: React.ComponentType;
  keyboard: React.ComponentType;
}

const AppLayout: React.FunctionComponent<AppLayoutProps> = ({
  adSection: AdSection,
  branding: Branding,
  navigation: Navigation,
  metrics: Metrics,
  contentBox: ContentBox,
  keyboard: Keyboard,
}) => {
  return (
    <StyledAppLayout>
      <AdSection></AdSection>
      <StyledMainContent>
        <Branding />
        <Navigation />
        <Metrics />
        <ContentBox />
        <Keyboard />
      </StyledMainContent>
      <AdSection></AdSection>
    </StyledAppLayout>
  );
};

export default AppLayout;

import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import AppLayout from "./components/AppLayout/AppLayout";
import Branding from "./components/Branding/Branding";
import ContentBox from "./components/ContentBox/ContentBox";
import Keyboard from "./components/Keyboard/Keyboard";
import MetricsBox from "./components/MetricsBox/MetricsBox";
import Navigation from "./components/Navigation/Navigation";
import store from "./store/store";
import theme from "./themes/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppLayout
          adSection={() => <div></div>}
          branding={Branding}
          navigation={Navigation}
          metrics={MetricsBox}
          contentBox={ContentBox}
          keyboard={Keyboard}
        />
      </Provider>
    </ThemeProvider>
  );
}

export default App;

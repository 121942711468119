import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ContentBoxReducer from "../components/ContentBox/ContentBox.slice";

export const RootReducer = combineReducers({
  ContentBox: ContentBoxReducer,
});

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatchType = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;
export default store;
